<template>
  <div class="">
    <div class="heading-sec">
      <div class="col-md-4 mt-6">
        <div class="poppins-medium font-30 heading-profile ml-7">Designers</div>
      </div>
    </div>
    <div class="panel-content bg-white border">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="font-20 widget-title float-left ml-10">Select Designers</div>
          <div style="float: right !important" class="mr-12" v-if="selectedVendor">
            <v-select
              v-if="!laoding"
              label="Select to Export"
              :items="exports"
              v-model="selectedExport"
              @change="exportBrandDataById(selectedVendor._id)"
              append-icon="▼"
              :disabled="laoding"
            >
            </v-select>
            <v-progress-circular indeterminate color="primary" v-if="laoding"></v-progress-circular>
          </div>
        </div>
        <div class="col-md-12 mb-4">
          <div class="form-group mx-4 pt-4" v-if="vendors">
            <label for="email"> Designer:</label>
            <v-select
              prepend-icon=""
              item-text="name"
              v-model="selectedVendor"
              :items="vendors"
              class="form-control"
              append-icon="▼"
              @change="selectedExport = ''"
            >
              <template v-slot:selection="{ item }">
                <v-row no-gutters>
                  <v-col cols="12" class="grey lighten-5">
                    <div>{{ item.brandName }}</div>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content class="grey lighten-5">
                    <div>{{ item.brandName }}</div>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vendorService from '../../../services/vendorService';
//import ConfirmDlg from '../../components/ConfirmDlg.vue';
//import productService from '../../services/productService';

export default {
  // components: { ConfirmDlg },
  data() {
    return {
      totalPages: 1,
      dialog: false,
      dialogFilter: false,
      searchText: '',
      vendors: [],
      selectedVendor: {},
      page: 1,
      formData: { limit: 10 },
      perPage: 10,
      errors: [],
      exports: [
        'Brand',
        'Customisation',
        'Cumulative',
        'Wishlist Products',
        'Assist Request',
      ],
      selectedExport: '',
      laoding: false,
    };
  },
  async created() {
    await this.getAllVendors();

    const vendor = await JSON.parse(localStorage.getItem('selectedVendor'));
    if (vendor !== '' && vendor !== undefined) {
      this.selectedVendor = this.vendors.find((item) => item._id === vendor);
    }
    console.log(this.selectedVendor);
    // this.getAllBrands();
  },
  watch: {
    selectedVendor(newVal) {
      this.$store.commit('setSelectedVendor', this.selectedVendor);
    },
  },

  methods: {
    async exportBrandDataById(vendorId) {
      if (this.selectedExport) {
        this.laoding = true;
        const data = {};
        data.vendorId = vendorId;
        const result = await vendorService.brandData(data, this.selectedExport);
        if (result.status === 200) {
          if (result.data.data !== 'No data found') window.open(result.data);
          this.$store.commit('setSnackbar', {
            content: result.data.data || 'Data Exported Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
        this.laoding = false;
        this.selectedExport = '';
      }
    },
    async getAllVendors() {
      this.dialogFilter = false;
      const data = {};
      data.page = this.page;
      data.filter = this.filter;
      const result = await vendorService.getAllIframeVendors(data);
      this.vendors = result.data.data;
      let vendorsList = [];
      this.vendors.map((item) => {
        vendorsList.push({ brandName: item.brandName, _id: item._id });
      });
      this.vendors = vendorsList;
    },
    async deleteVendorById(id) {
      const result = await vendorService.deleteVendor(id);
      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Submitted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
    },
    async deleteConfirm(id) {
      if (await this.$refs.confirm.open('Confirm', 'Are you sure you want to delete?')) {
        this.deleteVendorById(id);
        console.log('got confirmation');
      }
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();

      this.brands = await result.data;
      console.log(this.brands, 'brands');
    },
  },
};
</script>

<style lang="scss" scoped>
.select {
  width: 140px;
  max-height: 60px;
  font-size: 11px;
}
.Create-btn {
  color: white !important;
}
</style>
